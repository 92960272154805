#MainContent {
  width: 100%;
  text-align: center;
  background-color: var(--background-color-white);
  margin: 0 auto;
  min-height: 80vh;
}

.Main-section {
  /* height: 800px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 1500px;
  margin: 0 auto;
}

.Main-image-container {
  /* flex: 1; */
  width: 96vw;
  height: 96vw;
  /* min-height: 100vw; */
  /* max-width: 1000px; */
  position: relative;
  overflow: hidden;
  object-fit: cover;
  border-radius: 50%;
  /* background: url(../../assets/pictures/Headshot.jpg); */
}

.Main-image-container img {
  object-fit: cover;
  width: 100%;
}

.Main-image-container:before {
  flex: 1;
  /* right: -50%;
  content: ""; */
  /* background: url(../../assets/pictures/Headshot.jpg); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  transform: skewX(-20deg);
  position: absolute;
  transform-origin: top right;
  height: 100%;
  min-height: 800px;
  max-width: 1000px;
  width: 150%; */
}


/* .Main-image-container-reverse {
  flex: 1;
  width: 50%;
  height: 100%;
  min-height: 800px;
  position: relative;
  overflow: hidden;
  
  transform: skewX(20deg);
  transform-origin: 0 0;
}

.Main-image-container-reverse:before {
  flex: 1;
  content: "";
  background: url(../../assets/pictures/SternPose.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  transform: skewX(-20deg);
  position: absolute;
  transform-origin: 0 0;
  left: -50%;
  height: 100%;
  min-height: 800px;
  width: 150%;
} */
.Main-text-container {
  flex: 1;
  align-self: center;
}

.Main-text-container p {
  padding: 4%;
  font-weight: 300;
}

/*

Large Screen

*/
@media only screen and (min-width: 992px) {
/* #MainContent {
  width: 100%;
  text-align: center;
  background-color: var(--background-color-white);
  margin: 0 auto;
  min-height: 80vh;
} */

.FirstName {
  text-align: center;
  font-size: 4rem;
  color: var(--text-accent-color);
  font-weight: 600;
  margin-top: 10vh;
  margin-bottom: 10vh;
  text-transform: capitalize;
}

/* .WelcomeTransition {
  opacity: 1;
  transition: opacity 1000ms ease-in;
} */

.WelcomeInvisible {
  opacity: 0;
  transition: opacity 1000ms ease-out;
}

.WelcomeVisible {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}
/* .FirstName {
  writing-mode: vertical-lr;
  text-orientation: upright;
  transform: rotate(45deg);
}

.LastName {
  transform: rotate(45deg);
} */

.Main-card {
  margin-bottom: 2%;
  padding: 2%;
  height: 20vh;
  width: 40vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Main-section {
  height: 800px;
  flex-direction: row;
  /* background: url(../../assets/pictures/SternPose.jpg);
  background-repeat: no-repeat;
  background-size: cover; */
  /* transform: skewX(-20deg); */
}

/* .Main-image-container {
  transform: skewX(35deg); 
  -ms-transform: skewX(35deg); 
  -webkit-transform: skewX(35deg); 
  width: 100px; 
  height: 92px; 
  border-right: 1px solid black; 
  border-left: 1px solid black; 
  
  position: relative;
  overflow: hidden;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}

.Main-image-container:before {
  content: "";
  transform: skewX(-35deg); 
  -ms-transform: skewX(-35deg); 
  -webkit-transform: skewX(-35deg); 
  background-image: url('../../assets/pictures/SternPose.jpg'); 
  background-repeat: no-repeat; 
  background-position: top left; 
  
  position: absolute;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  width: 2000px; 
  height: 2000px; 
} */

.Main-image-container {
  flex: 1;
  width: 50%;
  height: 100%;
  min-height: 800px;
  max-width: 1000px;
  /* border: 1px solid black; */
  position: relative;
  overflow: hidden;
  
  transform: skewX(20deg);
  transform-origin: bottom right;
  border-radius: initial;
}

.Main-image-container:before {
  flex: 1;
  /* margin-left: -50%; */
  right: -50%;
  content: "";
  background: url(../../assets/pictures/Headshot.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  transform: skewX(-20deg);
  position: absolute;
  transform-origin: top right;
  height: 100%;
  min-height: 800px;
  max-width: 1000px;
  width: 150%;
}


.Main-image-container-reverse {
  flex: 1;
  width: 50%;
  height: 100%;
  min-height: 800px;
  /* border: 1px solid black; */
  position: relative;
  overflow: hidden;
  
  transform: skewX(20deg);
  transform-origin: 0 0;
}

.Main-image-container-reverse:before {
  flex: 1;
  /* margin-right: -50%; */
  content: "";
  background: url(../../assets/pictures/SternPose.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  transform: skewX(-20deg);
  position: absolute;
  transform-origin: 0 0;
  left: -50%;
  height: 100%;
  min-height: 800px;
  width: 150%;
}
/* .Main-text-container {
  flex: 1;
  align-self: center; */
  /* z-index: 1;
  width: 100%;
  height: 50%;
  padding-top: 200px; */
  /* padding-top: 50%;
  padding-left: -50%; */
  /* background-color: white;
  transform: skewY(10deg);
  overflow: hidden; */
/* } */

.Main-text-container p {
  /* padding: 4%;
  font-weight: 300; */
  font-size: 1.5rem;
}
}