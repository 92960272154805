
.Nav-container {
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color-white);
  width: 100%;
}

.Nav-container-sticky {

}

.Nav-item {
  text-transform: uppercase;
  text-decoration: none;
  color: var(--text-primary-color);
  margin: 4% 2%;
}

.Nav-item-active {
  text-decoration: line-through;
}

.Nav-left-container {
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 4%;
} 

.Nav-left-logo-container {
  height: 36px;
}

.Nav-right-container {
  flex: 1.5 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 4%;
}

.Nav-menu-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Nav-menu-overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: var(--background-color-white);
  opacity: 0.9;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
}

.Nav-menu-times {
  position: fixed;
  top: 0;
  right: 0;
  margin: 4vh;
}

@media only screen and (min-width: 992px) {
.Nav-container {
  height: 96px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 1% 1%; */
  background-color: var(--background-color-white);
  width: 100%;
}

.Nav-container-sticky {
  height: 48px;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 1px 1px 2px var(--shadow-color-one);
  animation: moveDown 0.5s ease-in-out;
}

.Nav-item {
  text-transform: uppercase;
  text-decoration: none;
  color: var(--text-primary-color);
  margin: 0 2%;
}

.Nav-item-active {
  text-decoration: line-through;
}

.Nav-left-container {
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 4%;
}

.Nav-left-logo-container {
  height: 44px;
}

.Nav-right-container {
  flex: 1.5 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding-right: 4%;
  /* justify-content: flex-end; */
}

.Nav-menu-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}
}