.Card-container {
  z-index: 2;
  box-shadow: 1px 1px 4px var(--shadow-color-one);
  background-color: var(--text-accent-color);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Card-container img {
  /* padding: 4px; */
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: contain;
}

/* .Card-container > p, .Card-container > span,
.Card-container > h1, .Card-container > h2, 
.Card-container > h3, .Card-container > h4,
.Card-container > h5, .Card-container > h6,
.Card-container > ul, .Card-container > li,
.Card-container > div, .Card-container > a
 {
  text-align: center;
  margin: 0 auto;
  padding: 4px;
} */