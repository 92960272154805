.Standard-button {
  color: var(--text-accent-color);
  /* background: var(--background-color-one); */
  min-height: 32px;
  text-transform: uppercase;
  width: 100%;
  min-width: 64px;
  max-width: 256px;
  border-radius: 12px;
  align-self: center;
  border: 1px var(--shadow-color-one) solid;
}