:root {
  --text-primary-color: #1f1f1f; 
  --text-accent-color: #fdfdfd;
  --background-color-one: #10D3AC;
  --background-color-two: #B7F1E6;
  --background-color-white: #fdfdfd;
  --background-color-black: #1f1f1f;
  --shadow-color-one: #6a6a6a;
  --shadow-color-two: #3f3f3f;
  --button-color-one: #7759F9;
  --button-color-two: #FF7272;
  --button-color-three: #F72585;
  --button-color-four: #7209B7;
  --button-color-five: #480CA8;
  --button-color-six: #3A0CA3;
  --button-color-seven: #4361EE;
  --button-color-eight: #4CC9F0;
}

p, h1, h2, h3, h4, h5, h6, span, div {
  margin: 0;
  border: 0;
  padding: 0;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(-45deg, var(--button-color-three), var(--button-color-two), var(--button-color-one), var(--button-color-two), var(--button-color-three));
  background-size: 600% 600%;
  animation: scrolling 20s ease infinite;
  color: var(--text-primary-color);
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}


.thin {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.gradient-button {
  background: linear-gradient(20deg, var(--button-color-one), var(--button-color-two), var(--button-color-three));
  /* background: linear-gradient(45deg, var(--button-color-three), var(--button-color-four), var(--button-color-five), var(--button-color-six), var(--button-color-seven), var(--button-color-eight)); */
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  -webkit-animation: gradient 10s ease infinite;
  -moz-animation: gradient 10s ease infinite;
}

.gradient-text {
  background: linear-gradient(20deg, var(--button-color-one), var(--button-color-two), var(--button-color-three));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  animation: gradient 10s ease infinite;
}

@-webkit-keyframes gradient {
  0%{background-position: 0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}

}

@-moz-keyframes gradient {
  0%{background-position: 0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}

}

@keyframes gradient {
  0%{background-position: 0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

@keyframes scrolling {
  0%{background-position: 0% 50%}
  50%{background-position:51% 100%}
  100%{background-position:99% 50%}
}