.tablet-card-container {
  margin: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--background-color-white);
  width: 500px;
  border: 1px solid black;
  padding: 8px;
}

.tablet-card-consensus {
  padding: 4px;
  text-align: left;
  hyphens: auto;
}

.tablet-card-container > img {
  width: 200px;
  height: 200px;
}

.tablet-card-container > ul {
  align-self: flex-start;
  list-style: none;
}

.tablet-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.tablet-icon-plus {
  color: green;
  margin-right: 8px;
}

.tablet-icon-minus {
  color: red;
  margin-right: 8px;
}