.Project-description {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 1rem;
  min-height: 50%;
  text-align: left;
  /* height: 100%; */
}

.Project-description > button {
  align-self: flex-end;
}

.Project-description > p {
  float: left;
}

.Project-description > h3 {
  text-transform: capitalize;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.PopUp-Overlay {
  min-width: 40vw;
  min-height: 30vh;
  height: 30vh;
  width: 40vw;
}