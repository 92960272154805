.Two-column-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* width: 100%; */
  padding: 2vh 4vw;
}

.Two-column-flex > * {
  flex: 1;
}