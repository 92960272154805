.Footer-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color-black);
  padding: 1% 0;
}

.Footer-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 80vw;
  margin: 2% auto;
  max-width: 400px;
}

#contact-form {
  width: 100%;
}

 .Footer-form-group {
  font-size: 0.75rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--shadow-color-one);
  margin-bottom: 0.5rem;
  /* padding-top: 1vh; */

}

.Footer-form-group label {
  text-transform: uppercase;
  font-size: 0.6rem;
  margin-bottom: 0.2rem;
}


/* .Footer-form-group > * {
  margin-bottom: 0.25rem;
} */

.Footer-form-group input,
.Footer-form-group textarea,
.Footer-form-submit {
  color: var(--text-accent-color);
  width: 100%;
  border: 1px var(--shadow-color-one) solid;
  /* border-radius: 2px; */
  background-color: var(--shadow-color-two);
  min-height: 24px;
}

.Footer-form-submit {
  text-transform: uppercase;
  min-height: 32px;
  border-radius: 16px;
  width: 50%;
  align-self: center;
  color: var(--text-accent-color);
}

.Footer-form-group:focus {
  color: red;
}

/*
  Large Screens
*/
@media only screen and (min-width: 992px) {
.Footer-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color-black);
  padding: 1% 0;
}

.Footer-form {
  min-width: initial;
  width: 40vw;
  margin: 2% auto;

}
 .Footer-form-group {
  font-size: 0.75rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--shadow-color-one);
  /* padding-top: 1vh; */

}


.Footer-form-group > * {
  margin-bottom: 0.25rem;
}

.Footer-form-group input,
.Footer-form-group textarea,
.Footer-form-submit {
  color: var(--text-accent-color);
  width: 100%;
  border: 1px var(--shadow-color-one) solid;
  border-radius: 2px;
  background-color: var(--shadow-color-two);
}

.Footer-form-submit {
  width: 50%;
  align-self: center;
  color: var(--text-accent-color);
}

.Footer-form-group:focus {
  color: red;
}
}