/* variables */
:root {
    /* --primary-color: #2CBEAB; */ /* mint  */
    /* --primary-color: #DE4B3E; */ /* google red */
    /* --primary-color: #EF6C34; */ /* blogger orange */
    /* --primary-color: #FFFC00; */ /* snapchat yellow */
    /* --primary-color: #1DB954; */ /* spotify green */
    /* --primary-color: #2D88FF; */ /* facebook blue */
    /* --primary-color: #9147FF; */ /* twitch purple */
    --primary-color: #2D88FF;
    --accent-color: #D1D1D1;
    --primary-text: black;
    --accent-text:#4f4f4f;
  }
  
  body, div, p, ul, li, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    border: 0;
  }
  html {
    background-color: grey;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
  }
  
  h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.75em;
  }
  h4 {
    font-size: 1.5em;
  }
  h5 {
    font-size: 1.25em;
  }
  h6 {
    font-size: 1em;
  }
  .primary {
    color: var(--primary-color);
  }
  .accent {
    color: var(--accent-color);
  }
  .primary-text {
  
  }
  .accent-text {
    color: var(--accent-text);
  }
  .thin {
    font-weight: 300;
  }
  .reg {
    font-weight: 400;
  }
  .semi-bold {
    font-weight: 600;
  }
  .bold {
    font-weight: 700;
  }
  
  .container {
    background-color: white;
    width: 100%;
    /* height: 100%; */
  }
  
  .main-container {
    padding: 1% 4%;
  }
  
  .header-container {
    padding: 1% 4%;
    overflow: hidden;
    border-bottom: 4px solid var(--primary-color);
  
  }
  
  /* original */
  
   body {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .container {
      /* width: 58%; */
      min-height: 100vh;
      min-width: 563px;
      /* margin-top: 1%;
      margin-bottom: 1%; */
    }
  
    .header-container {
      height: 144px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  
  
    .main-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      }
  
  
  /* media selectors */
  
  /* @media only screen and (min-width: 600px) {
    body {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .container {
      width: 58%;
      min-height: 90vh;
      min-width: 563px;
      max-width: 1200px;
      margin-top: 1%;
      margin-bottom: 1%;
    }
  
    .header-container {
      height: 144px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
  
  @media only screen and (min-width: 1000px) {
  
    .main-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      }
  } */
  
  
  
  .header {
    /* height: 160px; */
    /* background-color: blue;
     */
     /* border-bottom: 4px solid var(--primary-color); */
  }
  
  
  
  .left-container {
    /* background-color: blue; */
    padding-top: 1%;
    flex: 1.5 0 auto;
    overflow: hidden;
    /* align-self: flex-start; */
  }
  
  .right-container {
    padding-top: 1.25%;
    /* background-color: red; */
    flex: 1 0 auto;
    /* align-self: flex-end; */
  }
  
  .social-media li {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .social-media {
    /* text-decoration: none;
    list-style: none; */
    font-size: 14px;
    float: right;
    /* text-align: right; */
    /* padding: 12px; */
  }
  /* .social-media td {
    text-align: center;
  } */
  
  .right {
    text-align: right;
  }
  
  .center {
    text-align: center;
  }
  
  .left {
    text-align: left;
  }
  /* .social-media li span{
    padding-right: 12px;
  } */
  /* .icon {
    float: right;
    width: 24px;
    text-align: right;
    height: 18px!important;
  } */
  
  .main-left {
    flex: 2.25;
    padding: 4px 12px;
  }
  .main-right {
    flex: 1;
    padding: 4px 12px;
  }
  
  .main-section {
    padding-top: 1vh;
  }
  
  .main-section-header {
    display: inline-block;
    border-bottom: 2px solid var(--primary-color);
    height: 32px;
    width: 100%;
    vertical-align: middle;
  }
  .main-section-header i {
    vertical-align: middle;
  }
  .main-section-header span {
    vertical-align: middle;
  }
  
  .main-section-content {
    padding: 4px;
    font-size: 14px;
  }
  
  .main-section-content-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1% 0;
    flex-wrap: wrap;
  }
  
  .main-section-content-flex-left {
    flex: 1;
    padding: 0 0.5%;
  }
  .main-section-content-flex-right {
    flex: 5;
    padding: 0 0.5%;
  }
  .main-section-content-flex-right ul {
    padding-left: 4%;
  }
  
  .main-section-content ul {
    padding-left: 6%
  }
  
  .skills {
    list-style: none;
  }
  
  .skills li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 6px;
  }
  
  .extra-skills {
    padding-left: 6%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  #explanation {
    display: table;
    margin: 0 auto;
    padding-top: 6px;
    /* text-align: center; */
  }
  
  .hobby-container {
    padding: 0.5rem;
    font-size: 0.75rem;
  }
  
  .main-section-content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    padding: 1% 0;
  }
  
  
  .hidden {
    color: transparent;
    font-size: 1pt;
  }
  /* print */
  /* @media print {
    html {
      width: 563px;
      height: 967px;
      font-size: 12px;
    }
  } */
  
  @media print {
    /* Reset Margins */
    html, body {
        width: 8.5in;
        margin: 0;
        padding: 0;
        font-size: 12pt; /* Adjust font size for print */
    }
  
    /* Adjust the page margins */
    @page {
        margin: 0.5in; /* Adjust the margin around the page */
    }
  
    /* Hide elements that are not necessary for the print version */
    .navbar, .footer, .no-print {
        display: none;
    }
  
    /* Ensure images or other content fit within the page */
    img {
        max-width: 100%;
        page-break-inside: avoid;
    }
  
    /* Style headings and text for clarity */
    h1, h2, h3 {
        page-break-after: avoid;
        font-weight: bold;
    }
  
    p, h2, h3 {
        orphans: 2;
        widows: 2;
    }
  
    /* Control table breaking across pages */
    table {
        page-break-inside: auto;
    }
  
    tr {
        page-break-inside: avoid;
        page-break-after: auto;
    }
  
    thead {
        display: table-header-group; /* Ensure thead is repeated on each page */
    }
  
    tfoot {
        display: table-footer-group; /* Ensure tfoot is repeated on each page */
    }
  }
  